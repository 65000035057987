import type { Schemas } from '#shopware'

export function useSearch(
  initialSearch = '',
  criteriaOverwrite: Schemas['Criteria']
) {
  const { apiClient } = useShopwareContext()
  const search = ref(initialSearch)

  const criteria = computed(() => {
    if (criteriaOverwrite) return criteriaOverwrite
    else
      return {
        limit: 10,
        filter: [
          {
            type: 'multi',
            operator: 'OR',
            queries: [
              {
                field: 'name',
                type: 'contains',
                value: search.value,
              },
              {
                field: 'productNumber',
                type: 'contains',
                value: search.value,
              },
            ],
          },
          {
            type: 'multi',
            operator: 'OR',
            queries: [
              {
                type: 'multi',
                operator: 'AND',
                queries: [
                  {
                    field: 'parentId',
                    type: 'equals',
                    value: null,
                  },
                  {
                    field: 'childCount',
                    type: 'equals',
                    value: 0,
                  },
                ],
              },
              {
                type: 'not',
                operator: 'AND',
                queries: [
                  {
                    field: 'parentId',
                    type: 'equals',
                    value: null,
                  },
                ],
              },
            ],
          },
        ],
        associations: {
          seoUrls: {},
          categories: {},
        },
      }
  })

  const fetch = async () => {
    const {
      data: { elements },
    } = await apiClient.invoke('readProductStream post /product', {
      body: { ...criteria.value },
    })
    return elements
  }

  const {
    data: products,
    refresh,
    status,
  } = useAsyncData('product-search-' + search.value, async () => {
    if (!search.value) {
      return []
    }
    return await fetch()
  })

  const loading = computed(() => status.value === 'pending')

  return {
    search,
    products,
    loading,
    refresh,
    criteria,
  }
}
